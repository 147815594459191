import React from "react";

function DribbbleIcon(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 0C22.397 0 0 22.397 0 50s22.397 50 50 50c27.549 0 50-22.397 50-50S77.549 0 50 0zm33.026 23.048a42.512 42.512 0 019.653 26.572c-1.41-.27-15.51-3.145-29.718-1.355-.325-.705-.597-1.465-.922-2.224a127.095 127.095 0 00-2.82-6.182c15.727-6.4 22.885-15.618 23.807-16.811zM50 7.375c10.846 0 20.77 4.068 28.308 10.738-.76 1.084-7.213 9.707-22.397 15.401-6.996-12.852-14.75-23.373-15.944-25 3.2-.76 6.562-1.139 10.033-1.139zm-18.167 4.013c1.139 1.519 8.731 12.094 15.835 24.675-19.956 5.314-37.581 5.206-39.48 5.206 2.766-13.232 11.714-24.24 23.645-29.88zM7.267 50.054v-1.301c1.844.054 22.56.325 43.872-6.074 1.247 2.386 2.386 4.826 3.47 7.267-.542.163-1.138.325-1.68.488C30.91 57.538 19.196 76.952 18.22 78.579 11.443 71.041 7.267 61.01 7.267 50.054zM50 92.734a42.41 42.41 0 01-26.193-9.003c.76-1.573 9.436-18.275 33.514-26.681.108-.054.163-.054.271-.109 6.02 15.564 8.46 28.634 9.11 32.376-5.151 2.223-10.79 3.416-16.702 3.416zm23.807-7.322c-.434-2.603-2.712-15.076-8.297-30.423 13.395-2.115 25.108 1.356 26.572 1.844-1.843 11.876-8.676 22.126-18.275 28.58z"
      />
    </svg>
  );
}

export default DribbbleIcon;
